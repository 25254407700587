<template>
  <div class="ele-table">
    <div class="tw" id="tw1">
      <el-table
        ref="dynamicTable"
        :data="tableData"
        style="width: 100%"
        row-key="id"
        :height="height"
        :max-height="maxHeight"
        @select="handleSelectChange"
        @header-click="handleHeaderClick"
        @sort-change="handleSortChange"
        @header-dragend="handleHeaderDragend"
        @select-all="handleSelectAll"
      >
        <el-table-column
          v-if="!hideRowSelection"
          type="selection"
          width="40"
          align="center"
          fixed
        >
        </el-table-column>
        <template v-for="(item, index) in columns">
            <el-table-column
              v-if="item.show"
              :key="index"
              :show-overflow-tooltip="true"
              :prop="item.prop"
              :width="item.width"
              :label="item.label"
            />
        </template>
      </el-table>
    </div>

    <div class="pw">
      <el-pagination
        v-if="!hidePagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="totalItems"
        :layout="layout"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
export default {
  name: "ele-table2",
  mixins: [AppComponentBase],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    totalItems: {
      type: Number,
      dafault: 0,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String | Number,
      default: "",
    },
    handleHeight: {
      type: String | Number,
      default: "",
    },
    maxHeight: {
      type: String | Number,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "mini",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    actionsType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    pageSizes: {
      type: Array,
      default: () => [50, 100, 200, 400],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    hideRowSelection: {
      type: Boolean,
      default: false,
    },
    showRowSelectionTips: {
      type: Boolean,
      default: true,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    selectType: {
      type: String,
      default: "checkbox",
    }
  },
  data() {
    return {
      tableShow: true,
      rtHeight: 138,
      ltHeight: 220,
      myColumns: [],
      selectedRowKeys: [],
      selectedRows: [],
      tableWidth: 0,
    };
  },
  computed: {
    modalH() {
      return window.innerHeight - window.innerHeight * 0.14 < 600
        ? 600
        : window.innerHeight - window.innerHeight * 0.14;
    },
    maxHeight() {
      if (this.isFull) {
        if (this.showRowSelectionTips && !this.hideRowSelection) {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            40 -
            (this.handleHeight ? this.handleHeight : 0) +
            "px"
          );
        } else {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            (this.handleHeight ? this.handleHeight : 0) +
            "px"
          );
        }
      } else {
        if (this.handleHeight) {
          return (
            window.innerHeight -
            this.rtHeight -
            70 -
            40 -
            this.handleHeight +
            "px"
          );
        } else {
          if (this.tableData.length) {
            if (this.showRowSelectionTips && !this.hideRowSelection) {
              return (
                window.innerHeight -
                this.rtHeight -
                220 +
                "px"
              );
            } else {
              return (
                window.innerHeight -
                this.rtHeight -
                180 +
                "px"
              );
            }
          } else {
            return "auto";
          }
        }
      }
    },
    rowStyle() {
      let obj = {
        height: "0px",
      };
      return obj;
    },
    cellStyle() {
      let obj = {
        padding: "4px 0px",
        fontSize: "13px",
      };
      return obj;
    },
    headerRowStyle() {
      let obj = {
        fontWeight: "bold",
        fontSize: "13px",
        color: "#1b2934",
        padding: "4px 0",
        background: "#eef1f6",
      };
      return obj;
    },
  },
};
</script>

<style lang="less">
.ele-table {
  padding: 0 5px;
  .slot-w {
    padding: 5px 0;
  }
  .tw {
  }
  .pw {
    padding: 5px 0;
    text-align: right;
  }
  .drop-item {
    padding: 5px 12px;

    &:hover {
      color: blue;
    }
  }
  .ant-dropdown-link {
    &:hover {
      color: blue;
    }
  }
  .table-edit,
  .table-delete {
    text-align: center;
    // width: 100%;
    &:hover {
      color: blue;
    }

    span {
      margin-left: 3px;
    }
  }
  .table-delete {
    margin-left: 6px;
  }
}
</style>
