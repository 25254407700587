<template>
  <a-spin :spinning="psspinning">
    <div class="up-container">
      <div class="up-tabs">
        <a-tabs :activeKey="CurrentTabKey" @change="HandleTabChange">
          <a-tab-pane key="all" tab="汇总">
            <div class="up-tab-content">&nbsp;</div>
          </a-tab-pane>
          <a-tab-pane key="waitSignture" tab="待盖章">
            <div class="up-tab-content">&nbsp;</div>
          </a-tab-pane>
          <!-- <a-tab-pane key="waitNotify" tab="待通知">
            <div class="up-tab-content">&nbsp;</div>
          </a-tab-pane> -->
          <a-tab-pane key="waitSendSign" tab="已通知待签署">
            <div class="up-tab-content">&nbsp;</div>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="up-table">
        <ele-table :columns="tableColumns" :table-data="tableData" :total-items="totalItems" :is-full="true"
          :current-page="pageNumber" :hide-row-selection="false" :max-height="tableMaxHeight" :height="tableMaxHeight"
          :actionsType="actionsType" :IsClear="IsClear" @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange" :pageSizes="[20, 40, 60, 80]" :pageSize="20">
          <a-row :gutter="24">
            <a-col span="24">
              <a-button type="default" icon="diff" class="up-btn up-warn" v-if="CurrentTabKey == 'waitSignture'"
                :loading="dloading" :disabled="dloading" @click="batchSignture">批量盖章</a-button>
              <a-button type="default" icon="stop" class="up-btn" v-if="CurrentTabKey == 'waitSignture'"
                :loading="dloading" :disabled="dloading" @click="batchNotSeal">无需盖章</a-button>
              <a-button type="default" icon="bell" class="up-btn up-blue"
                v-if="CurrentTabKey == 'waitNotify' || CurrentTabKey == 'waitSendSign'" :loading="dloading"
                :disabled="dloading" @click="batchNotify">批量通知</a-button>
              <a-button type="default" icon="reload" class="up-btn up-danger"
                v-if="CurrentTabKey == 'waitNotify' || CurrentTabKey == 'waitSendSign'" :loading="dloading"
                :disabled="dloading" @click="batchCancel">批量撤回</a-button>
              <a-button type="default" icon="to-top" class="up-btn up-green"
                v-if="CurrentTabKey == 'all' || CurrentTabKey == 'waitNotify'" :loading="dloading" :disabled="dloading"
                @click="batchSendSignture">批量发起签署</a-button>
              <a-button type="default" icon="export" class="up-btn up-purple"
                v-if="CurrentTabKey == 'waitNotify' || CurrentTabKey == 'waitSendSign'" :loading="dloading"
                :disabled="dloading" @click="exportCancelInfo">导出撤销信息</a-button>
              <a-button type="primary" icon="download" :loading="dloading" :disabled="dloading"
                @click="exportList">导出</a-button>
            </a-col>
          </a-row>
          <a-row :gutter="24" class="up-search">
            <a-col span="5">
              <a-input placeholder="请选择项目" :allowClear="true" v-model="projectInfo.value" @click="selectProjectModal" />
            </a-col>
            <a-col span="5">
              <a-input placeholder="协议名称" :allowClear="true" v-model="request.agreementName"
                @click="selectProjectAgreement" />
            </a-col>
            <a-col span="5">
              <a-input placeholder="姓名/手机号/身份证号" :allowClear="true" v-model="request.keyword" />
            </a-col>
            <a-col span="5">
              <a-select placeholder="签署来源" :allowClear="true" v-model="request.signSource" :options="SignSources"
                style="width:100%">
              </a-select>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col span="5">
              <a-select placeholder="签署状态" :allowClear="true" v-model="request.signStatus" :options="SignStatus"
                style="width:100%">
              </a-select>
            </a-col>
            <a-col span="5">
              <a-select placeholder="离职属性" :allowClear="true" v-model="request.departureStatus"
                :options="DepartureStatus" style="width:100%">
              </a-select>
            </a-col>
            <a-col span="5">
              <a-range-picker @change="searchDateChange" v-model="rangeDate" :placeholder="[l('开始日期'), l('结束日期')]"
                valueFormat="YYYY-MM-DD" />
            </a-col>
            <!-- <a-col span="5">
              <a-select 
                placeholder="未签约天数" 
                :allowClear="true" 
                v-model="request.notSignDay" 
                :options="NotSignDays" 
                style="width:100%"
              >
              </a-select>
            </a-col> -->
            <a-col span="5">
              <a-select placeholder="前置协议签署状态" :allowClear="true" v-model="request.preAgreementSignStatus"
                :options="PreAgreementSignStatus" style="width:100%">
              </a-select>
            </a-col>
            <a-col span="4">
              <a-button type="default" class="up-btn up-blue" icon="search" @click="searchData">查询</a-button>
              <a-button type="default" icon="sync" @click="resetData">重置</a-button>
            </a-col>
          </a-row>
        </ele-table>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from '@/components/ele-table';
import moment from 'moment';
import StepModal from './batchsend.vue';
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";
import shouldShowSealError from '@/components/CanSealInfo/check.js';

export default {
  mixins: [AppComponentBase],
  components: {
    EleTable
  },
  data () {
    const height = window.screen.availHeight - 500;
    return {
      commonService: null,
      tableMaxHeight: height,
      psspinning: false,
      dloading: false,
      IsClear: false,
      tableColumns: [],
      tableData: [],
      rangeDate: [],
      totalItems: 0,
      pageNumber: 1,
      projectInfo: {
        value: '',//项目名称
        ids: undefined//项目id
      },
      selectedRowKeys: [],
      selectedRows: [],
      request: {
        skipCount: 0,
        maxResultCount: 20,
        status: null,
        agreementName: null,//协议名称
        keyword: null,//姓名、身份证号、手机号
        signSource: undefined,//签署来源
        signStatus: undefined,//签署状态
        departureStatus: undefined,//离职状态
        // notSignDay: undefined,//未签约时间（天) //2022-03-31 不需要此查询条件了
        preAgreementSignStatus: undefined, //前置协议签署状态
        startTime: undefined,
        endTime: undefined
      },
      CurrentTabKey: 'all',
      SignSources: [
        { label: '扫码签署', value: 1 },
        { label: '后台下发', value: 2 },
      ],
      SignStatus: [
        { label: '未签署', value: 0 },
        { label: '已通知待签署', value: 4 },
        { label: '单方已签署', value: 2 },
        { label: '已归档', value: 3 },
      ],
      DepartureStatus: [
        { label: '正常离职', value: 1 },
        { label: '闪离', value: 2 }
      ],
      NotSignDays: [
        { label: '未签约天数>3', value: 1 },
        { label: '未签约天数<=3', value: 2 },
      ],
      PreAgreementSignStatus: [
        { label: '未签署', value: 1 },
        { label: '已签署', value: 2 },
      ],
      actionsType: null
    }
  },
  methods: {
    searchDateChange (e) {
      if (e && e.length > 0) {
        this.request.startTime = e[0];
        this.request.endTime = e[1];
      } else {
        this.request.startTime = undefined;
        this.request.endTime = undefined;
      }
    },
    HandleTabChange (key) {
      this.CurrentTabKey = key;
      switch (key) {
        case 'all':
          this.request.status = null;
          break;
        case 'waitSignture':
          this.request.status = 1;
          break;
        case 'waitNotify':
          this.request.status = 2;
          break;
        case 'waitSendSign':
          this.request.status = 3;
          break;
      }
      this.searchData();
    },
    resetData () {
      this.request.agreementName = null;//协议名称
      this.request.keyword = null;//姓名、身份证号、手机号
      this.request.signSource = undefined;//签署来源
      this.request.signStatus = undefined;//签署状态
      this.request.departureStatus = undefined;//离职状态
      this.request.notSignDay = undefined;//未签约时间（天)
      this.request.preAgreementSignStatus = undefined; //前置协议签署状态
      this.projectInfo.value = '';
      this.projectInfo.ids = undefined;
      this.request.startTime = undefined;
      this.request.endTime = undefined;
      this.request.agreementId = null;
    },
    clearFilterAndRefresh () {
      this.resetData();
      this.searchData();
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys.splice(0, this.selectedRowKeys.length);
      this.selectedRowKeys = [...selectedRowKeys];
      this.selectedRows.splice(0, this.selectedRows.length);
      this.selectedRows = [...selectedRows];
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.searchData();
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.searchData();
    },
    searchData () {
      if (!this.request.agreementName || this.request.agreementName == '') {
        this.request.agreementId = null;
      }
      this.psspinning = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSign/GetPage',
        params: {
          projectIds: this.projectInfo.ids,
          ...this.request
        }
      }).then(res => {
        this.IsClear = !this.IsClear;
        this.psspinning = false;
        this.tableData.splice(0, this.tableData.length);
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        res.items.map(item => {
          this.tableData.push(item);
          return null;
        });
      }).catch(e => {
        this.psspinning = false;
        console.error(e);
      })
    },
    /*
    批量签章
    */
    batchSignture () {
      this.dloading = true;
      if (!this.checkSelectRows(['Signed', 'Simple'], '当前含有非待盖章状态的协议，请核实')) {
        this.dloading = false;
        return false;
      }

      this.commonService.post({
        url: '/api/services/app/UPersonSign/BatchHandSignture',
        params: { ids: this.selectedRowKeys }
      }).then(res => {
        var errors = res.filter(it => it.isSuccess === false);
        if (errors && errors.length > 0) {
          // let message = '';
          // errors.map(item => {
          //   var rows = this.tableData.filter(it => it.id == item.id);
          //   var row = rows[0];
          //   message += '[' + row.realName + '][' + row.agreementName + ']盖章失败(' + item.reason + ')，';
          //   return null;
          // });
          // abp.message.error(message);
          shouldShowSealError({ tableData: res }, () => {
            this.searchData();
          });
        } else {
          abp.message.success('操作成功');
          setTimeout(() => {
            this.searchData();
          }, 1000);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.dloading = false;
      });
    },
    /**
     * 无需盖章
     */
    batchNotSeal () {
      this.dloading = true;
      debugger;
      if (!this.checkSelectRows(['Simple'], '当前含有非待盖章状态的协议，请核实')) {
        this.dloading = false;
        return false;
      }

      this.commonService.post({
        url: '/api/services/app/UPersonSign/NoNeedSign',
        params: { ids: this.selectedRowKeys }
      }).then(res => {
        abp.message.success('操作成功');
        this.searchData();
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.dloading = false;
      });
    },
    /*
    * 批量通知
    */
    batchNotify () {
      this.dloading = true;
      if (!this.checkSelectRows(['None', 'Notified'], '当前含有非未签署状态的协议，请核实')) {
        this.dloading = false;
        return false;
      }

      this.commonService.post({
        url: '/api/services/app/UPersonSign/SendSignNotice',
        params: { ids: this.selectedRowKeys }
      }).then(res => {
        if (res.errorCount > 0) {
          abp.message.warn(res.message);
        } else {
          abp.message.success('操作成功');
          this.searchData();
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.dloading = false;
      });
    },
    /**
     * 批量撤回
     */
    batchCancel () {
      this.dloading = true;
      if (!this.checkSelectRows(['None', 'Notified'], '当前含有非未签署状态的协议，请核实')) {
        this.dloading = false;
        return false;
      }

      this.commonService.post({
        url: '/api/services/app/UPersonSign/Revocation',
        params: { ids: this.selectedRowKeys }
      }).then(res => {
        if (res.errorCount > 0) {
          abp.message.warn(res.message);
        } else {
          abp.message.success('操作成功');
          this.searchData();
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        this.dloading = false;
      });
    },
    /**
     * 批量发起签署
     */
    batchSendSignture () {
      ModalHelper.create(
        StepModal, {},
        {
          isChange: true,
          width: "900px",
          heigth: "700px",
        }
      ).subscribe(res => {
        console.warn(res);
      });
    },
    /**
     * 导出撤销信息
     */
    exportCancelInfo () {
      this.dloading = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSign/ExportCancelInfo',
        params: {
          projectIds: this.projectInfo.ids,
          ...this.request
        }
      }).then(res => {
        fileDownloadService.downloadTempFile(res);
      }).finally(() => {
        this.dloading = false;
      });
    },
    /**导出 */
    exportList () {
      this.dloading = true;
      this.commonService.post({
        url: '/api/services/app/UPersonSign/Export',
        params: {
          projectIds: this.projectInfo.ids,
          ...this.request
        }
      }).then(res => {
        fileDownloadService.downloadTempFile(res);
      }).finally(() => {
        this.dloading = false;
      });
    },
    checkSelectRows (statusArray, message) {
      if (!this.selectedRowKeys || this.selectedRowKeys.length <= 0) {
        abp.message.warn('至少选择一行');
        return false;
      }
      var rows = this.selectedRows.filter(item => statusArray.filter(t => t == item.signStatus).length <= 0);
      if (rows.length > 0) {
        abp.message.warn(message);
        return false;
      }
      return true;
    },
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "radio", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = [data.id];
          let projectNames = data.name;

          this.projectInfo.ids = projectIds; //data.id;
          this.projectInfo.value = projectNames; //data.name;
          this.searchData();
        }
      });
    },
    selectProjectAgreement () {
      if (this.projectInfo.ids && this.projectInfo.ids !== '') {
        ModalHelper.create(
          SelectPageList,
          {
            selectType: "radio", // checkbox radio
            title: "选择项目协议", // 显示标题
            searchPlaceholder: "协议名称",
            url: "/api/services/app/UnnaturalPersonProjectAgreement/GetPageByProjectId?projectId=" + this.projectInfo.ids, // 数据接口URL
            columnsData: [
              {
                title: "协议名称",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "描述",
                dataIndex: "describe",
                key: "describe",
              },
              {
                title: "是否默认",
                dataIndex: "isDefault",
                key: "isDefault",
              },
            ],
          },
          {
            isChange: true,
            width: "860px",
            heigth: "650px",
          }
        ).subscribe((res) => {
          console.warn(res);
          const { success, data } = res;
          if (success) {
            this.request.agreementName = data.name; //data.name;
            this.request.agreementId = data.id;
            this.searchData();
          }
        });
      } else {
        abp.message.warn("请先选择项目");
      }
    }
  },
  created () {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.tableColumns = [
      {
        title: "项目编码",
        dataIndex: "projectCode",
        sorter: false,
        align: "center",
        width: 150,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "projectCode" },
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        sorter: false,
        align: "center",
        showOverflowTooltip: true,
        width: 150,
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "姓名",
        dataIndex: "realName",
        sorter: false,
        align: "center",
        width: 120,
        scopedSlots: { customRender: "realName" },
      },
      {
        title: "手机号",
        dataIndex: "phoneNumber",
        sorter: false,
        align: "center",
        width: 120,
        scopedSlots: { customRender: "phoneNumber" },
      },
      {
        title: "身份证号",
        dataIndex: "idCard",
        sorter: false,
        align: "center",
        showOverflowTooltip: true,
        width: 150,
        scopedSlots: { customRender: "idCard" },
      },
      {
        title: "入职日期",
        dataIndex: "inDate",
        sorter: false,
        align: "center",
        width: 150,
        scopedSlots: { customRender: "inDate" },
        type: 'custom',
        customRender: (text) => {
          if (text && text !== '') {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        }
      },
      {
        title: "离职属性",
        dataIndex: "departureStatus",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "departureStatus" },
      },
      {
        title: "离职日期",
        dataIndex: "outDate",
        sorter: false,
        align: "center",
        width: 120,
        scopedSlots: { customRender: "outDate" },
        type: 'custom',
        customRender: (text) => {
          if (text && text !== '') {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        }
      },
      {
        title: "未签约天数",
        dataIndex: "notSignDay",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "notSignDay" },
      },
      {
        title: "协议名称",
        dataIndex: "agreementName",
        sorter: false,
        align: "center",
        showOverflowTooltip: true,
        width: 120,
        scopedSlots: { customRender: "agreementName" },
      },
      {
        title: "签署状态",
        dataIndex: "signStatus",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "signStatus" },
        type: "tag",
        getColor: (text, row) => {
          switch (text) {
            case 'None':
              return 'crimson'
            case 'Notified':
              return 'yellow'
            case 'Simple':
            case 'Signed':
              return 'blue'
            case 'Archive':
              return 'green'
            default:
              return '#999'
          }
        },
        getText: (text, row) => {
          switch (text) {
            case 'None':
              return '未签署'
            case 'Notified':
              return '已通知待签署'
            case 'Simple':
              return '单方已签署'
            case 'Signed':
              return '待盖章'
            case 'Archive':
              return '已归档'
            default:
              return '未知'
          }
        }
      },
      {
        title: "签署时间",
        dataIndex: "signTime",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "signTime" },
        type: 'custom',
        customRender: (text) => {
          if (text && text !== '') {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        }
      },
      {
        title: "前置协议签署状态",
        dataIndex: "preAgreementStatus",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "preAgreementStatus" },
      },
      {
        title: "前置协议",
        dataIndex: "preAgreements",
        sorter: false,
        align: "center",
        width: 180,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "preAgreements" },
        type: 'custom',
        customRender: (text, item) => {
          if (text && text.length > 0) {
            let html = '';
            text.map(item => {
              html += item.agreementName + '，';
              return null;
            });
            if (html.length > 0) {
              html = html.substring(0, html.length - 1);
            }
            return html;
          }
          return '-';
        }
      },
      {
        title: "签署来源",
        dataIndex: "signSource",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "signSource" },
      },
      {
        title: "发起人",
        dataIndex: "creator",
        sorter: false,
        align: "center",
        width: 120,
        scopedSlots: { customRender: "creator" },
      },
      {
        title: "协议下发时间",
        dataIndex: "createdTime",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "createdTime" },
        type: 'custom',
        customRender: (text) => {
          if (text && text !== '') {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        }
      },
      {
        title: "通知状态",
        dataIndex: "isNotify",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "isNotify" },
      },
      {
        title: "通知时间",
        dataIndex: "notifyTime",
        sorter: false,
        align: "center",
        width: 120,
        showOverflowTooltip: true,
        scopedSlots: { customRender: "notifyTime" },
        type: 'custom',
        customRender: (text) => {
          if (text && text !== '') {
            return moment(text).format('YYYY-MM-DD HH:mm:ss');
          }
          return '-';
        }
      },
      {
        title: "通知次数",
        dataIndex: "notifyCount",
        sorter: false,
        align: "center",
        width: 100,
        scopedSlots: { customRender: "notifyCount" },
      },
      {
        title: this.l("Actions"),
        fixed: "right",
        type: 'actions',
        scopedSlots: { customRender: "actions" },
      }
    ];
    this.actionsType = {
      type: "personalSign",
      isShow: true,
      fns: {
        download: {
          granted: this.isGranted("Pages.PersonSignManager.Query"),
          name: this.l("下载"),
          icon: "download",
          width: "30%",
          fn: (data) => {
            if (data.downloadUrl && data.downloadUrl !== '') {
              widnwo.open(data.downloadUrl);
            } else {
              abp.message.warn('当前状态无法下载');
            }
          },
        },
        viewPdf: {
          granted: this.isGranted("Pages.PersonSignManager.Query"),
          name: this.l("查看"),
          icon: "eye",
          width: "30%",
          fn: (data) => {
            if (data.viewUrl && data.viewUrl !== '') {
              widnwo.open(data.viewUrl);
            } else {
              abp.message.warn('当前状态无法查看');
            }
          },
        }
      }
    }
    this.searchData();
  }
}
</script>
<style>
.up-tab-content {
  /* height: 1px; */
}

.up-btn {
  margin-right: 7px;
}

.up-warn {
  background-color: rgb(237, 173, 15);
  color: white;
}

.up-danger {
  background-color: crimson;
  color: white;
}

.up-blue {
  background-color: rgb(30, 159, 225);
  color: white;
}

.up-green {
  background-color: rgb(0, 150, 136);
  color: white;
}

.up-purple {
  background-color: rgb(78, 115, 223);
  color: white;
}

.up-search {
  margin-top: 8px;
  margin-bottom: 8px;
}

.up-table {
  width: 99.5%;
}
</style>
